body, html {
  background-color: rgb(184, 150, 112);
}

.App {
  text-align: center;
  background-color: rgb(184, 150, 112);
  background-image: url(./img/desktop-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 960px) and (min-width: 481px) {
  .App {
    background-image: url(./img/tablet-bg.png);
  }
}

@media (max-width: 480px) {
  .App {
    background-image: url(./img/mobile-bg.png);
  }
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #111;
}

h1, h2, h3, h4, h5, h6, a {
  color: #111;
  font-family: 'OldNewspaperTypes';
}

.App-links {
  padding-top: 3vh;
}

a:hover {
  color:rgb(184, 64, 48);
}

@media (max-width: 480px) {
  .App-logo {
    height: 80vmin;
    pointer-events: none;
  }
  .App-link {
    font-size: calc(10px + 4vmin);
  }
}

@font-face {
  font-family: 'OldNewspaperTypes';
  src:url('./fonts/OldNewspaperTypes.ttf.woff') format('woff'),
      url('./fonts/OldNewspaperTypes.ttf.svg#OldNewspaperTypes') format('svg'),
      url('./fonts/OldNewspaperTypes.ttf.eot'),
      url('./fonts/OldNewspaperTypes.ttf.eot?#iefix') format('embedded-opentype'); 
  font-weight: normal;
  font-style: normal;
}